import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Typography,
    Box
} from '@mui/material';

import { 
    businessPartner, 
    hideCustomerTypes,
    markets, 
    preSelectedMarket ,
    selectedMarket
} from '../../redux/Selectors.js';
import AppHeader from '../../components/AppHeader';
import MarketPicker from '../../components/MarketPicker';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { setUpsellingDone } from '../../redux/Actions.js';
import { getPage, DEVICE_MOBILE } from '../../common/navigationTools.js';
import CustomerType from '../../components/CustomerType.js';

/**
 * @returns 
 */
export const Markets = () => {

    const windowHeight = window.innerHeight;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [market, setMarket] = useState(null);

    const _markets = useSelector((state) => markets(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _preSelectedMarket = useSelector((state) => preSelectedMarket(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _hideCustomerTypes = useSelector((state) => hideCustomerTypes(state));

    const NEXT = getPage('templates', DEVICE_MOBILE);
    const LABEL_NEXT = 'm.navigation.toTemplates';

    const next = () => {
        navigate(getPage('templates', DEVICE_MOBILE));
    }

    useEffect(() => {
        if (market) {
            navigate(NEXT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [market]);

    useEffect(() => {
        setMarket(_selectedMarket);
    }, [_selectedMarket]);

    useEffect(() => {
        setMarket(_preSelectedMarket);
        dispatch(setUpsellingDone(false));
        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>

            <Stack sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                {!_businessPartner && !_hideCustomerTypes && (<CustomerType />)}
                <Typography sx={{ mt: '1rem' }} variant="mobilePagePrimaryLabel" tabIndex={0}>{t('m.markets.choose')}</Typography>
                {_markets && (<MarketPicker items={_markets} next={next}></MarketPicker>)}
            </Stack>
            {_selectedMarket ?
                (<NavigationBar
                    next={{ clicked: next, label: LABEL_NEXT }}>
                </NavigationBar>) : (<Box sx={{ height: 150 }} />)}
            <InfoText />
        </Stack >
    );
}

export default Markets;
