import React from 'react';

import store from "../redux/Store";

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    orderToEdit,
    selectedMarket,
    selectedTemplate,
} from '../redux/Selectors.js';
import {
    fetchAdBoxNumberTypes,
    fetchTemplateCriteria,
    fetchTemplateStructure,
    fetchTemplateComponents,
    fetchTemplateOnlineId
} from '../redux/Actions.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CreativeService = () => {

    const dispatch = useDispatch();
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state))

    /**
     * Hook to load template dependencies 
     */
    useEffect(() => {
        if (_selectedTemplate && _selectedMarket) {
            dispatch(fetchAdBoxNumberTypes(_selectedTemplate._id));

        }
    }, [_selectedMarket, _selectedTemplate]);

    const fetchTemplateDependencies = async (template) => {
        //       const template = _templates.find(e => e._id === templateId);
        const templateDependencies = [];

        templateDependencies.push(dispatch(fetchAdBoxNumberTypes(template._id)));
        templateDependencies.push(dispatch(fetchTemplateCriteria(template._links.criteria.href)));
        templateDependencies.push(dispatch(fetchTemplateStructure(template._links.source.href)));
        templateDependencies.push(dispatch(fetchTemplateComponents(template._links.components.href)));
        if (template?.forceOnlineId && template?._links?.onlineId && !_orderToEdit) {
            templateDependencies.push(dispatch(fetchTemplateOnlineId(template, _selectedMarket.onlineIdPrefix ? _selectedMarket.onlineIdPrefix : "")));
        }
        templateDependencies.push(dispatch(fetchAdBoxNumberTypes(template._id)));
        Promise.all(templateDependencies)
            .then(values => {
                console.log("loaded templateDependencies");
                //               dispatch(setTemplate(templateId));
                //dispatch(setAdBoxNumber());
            });
    };

    useEffect(() => {
        if (_selectedTemplate) {
            fetchTemplateDependencies(_selectedTemplate, _orderToEdit);
        }
    }, [_selectedTemplate, _orderToEdit]);

    return (<></>);
}

/**
 * 
 * @param {*} template 
 * @returns 
 */
export const fetchTemplateDependencies = (template, orderToEdit = undefined, onlineIdPrefix = '') => {
    return new Promise((resolve, reject) => {
        const dispatch = store.dispatch;
        const templateDependencies = [];
        templateDependencies.push(dispatch(fetchAdBoxNumberTypes(template._id)));
        templateDependencies.push(dispatch(fetchTemplateCriteria(template._links.criteria.href)));
        templateDependencies.push(dispatch(fetchTemplateStructure(template._links.source.href)));
        templateDependencies.push(dispatch(fetchTemplateComponents(template._links.components.href)));
        if (template?.forceOnlineId && template?._links?.onlineId && !orderToEdit) {
            templateDependencies.push(dispatch(fetchTemplateOnlineId(template, onlineIdPrefix)));
        }
        Promise.all(templateDependencies)
            .then(() => {
                resolve(template._id);
            });
    });
};
