import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Stack,
    Typography
} from '@mui/material';
import {
    offers,
    pendingOrder,
    preSelectedOffer,
    properties,
    selectedMarket,
    selectedOffer
} from '../../redux/Selectors.js';
import {
    calculateOfferPrice,
    setOffer
} from '../../redux/Actions.js';
import AppHeader from '../../components/AppHeader';
import SelectedMarket from '../components/SelectedMarket';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import OfferPicker from '../../components/common/OfferPicker.jsx';
import { OfferCard } from '../../components/common/Offer.jsx';
import { getPage, DEVICE_MOBILE } from '../../common/navigationTools.js';
import { useDebounce } from '../../hooks/Hooks.js';

/**
 * @returns 
 */
export const Offers = () => {

    const dispatch = useDispatch();

    const windowHeight = window.innerHeight;

    const { t } = useTranslation();
    const _offers = useSelector((state) => offers(state));
    const _pendingOrder = useSelector((state) => pendingOrder(state));
    const _preSelectedOffer = useSelector((state) => preSelectedOffer(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));

    const navigate = useNavigate();
    const [height, setHeight] = useState(window.innerHeight - 500);

    const BACK = getPage('creative', DEVICE_MOBILE);
    const LABEL_BACK = 'm.offers.back';
    const NEXT = getPage(_selectedOffer?.editionsSelectable ? 'editions' : 'schedule', DEVICE_MOBILE);
    const LABEL_NEXT = _selectedOffer?.editionsSelectable ? t('m.offers.next.editions') : t('m.offers.next');

    const listenResize = () => {
        setHeight(window.innerHeight - 500);
    };

    useEffect(() => {
        window.addEventListener('resize', listenResize);
        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        if (_preSelectedOffer) {
            dispatch(setOffer(_preSelectedOffer._id));
            next();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_preSelectedOffer]);

    /**
     * Hook to calculate offer prices
     */
    useEffect(() => {
        if (_pendingOrder && _offers) {
            calculateOfferPrices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_pendingOrder, _offers]);

    /**
     * 
     */
    const calculateOfferPrices = useDebounce(() => {
        _offers.forEach(o => {
            const order = Object.assign({}, _pendingOrder);
            if (order.marketId && order.motif) {
                // Angebotspez. Werte setzen:
                order.offerId = o._id;
                order.selectedEditionIds = o.editionsSelectable && _pendingOrder.selectedEditionIds && _pendingOrder.selectedEditionIds && _pendingOrder.selectedEditionIds.length > 0
                    ? _pendingOrder.selectedEditionIds.slice()
                    : o.preSelectedEditions.slice();
                order.selectedEditionIds = o.editionsSelectable && o.editions && o.editions.length > 0 && o.editions.indexOf(order.selectedEditionIds[0]) === -1
                    ? o.preSelectedEditions.slice()
                    : order.selectedEditionIds;
                // }
                if (order.templateId && (order.selectedEditionIds.length > 0 || !o.editionsSelectable)) {
                    dispatch(calculateOfferPrice(order, o));
                }
            }
        });
    }, 500);

    const SelectedOffer = ({ offer }) => {
        return (
            offer && (
                <Box onClick={next}>
                    <OfferCard offer={offer} highlight={true} properties={_properties} image={false} />
                </Box>
            ))
    };

    const back = () => {
        navigate(BACK);
    };

    const next = () => {
        navigate(NEXT);
    };

    return height ? (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <SelectedMarket market={_selectedMarket}></SelectedMarket>
                <Divider />
                <Typography variant="mobilePageSubTitle">{t('m.offers.title')}:</Typography>
                <SelectedOffer offer={_selectedOffer} ></SelectedOffer>
                {_offers.length > 1 && (
                    <Typography variant="mobilePageSubTitle">{t('m.offers.alternatives')}:</Typography>
                )}
                <Box xsx={{ height, overflowY: "scroll" }}>
                    <OfferPicker variant="small" items={_offers}></OfferPicker>
                </Box>
            </Stack>
            <NavigationBar
                back={{ clicked: back, label: t(LABEL_BACK) }}
                next={{ clicked: next, label: t(LABEL_NEXT) }}>
            </NavigationBar>
            <InfoText />
        </Stack>
    ) : <></>;
}

export default Offers;
