import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid, Stack, Box, Button } from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import format from 'date-fns/format';
import {
    orderPriceResponse, editionDisplayNames, selectedOffer, offers,
    orderToEdit
} from '../../redux/Selectors';
import { Label, DataBold } from '../common/Commons';
import { DataDivider, EditIcon } from '../../common/widgetTools';
import { DEVICE_DESKTOP, getPage } from '../../common/navigationTools';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const DataCard = ({ height }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));
    const _editionDisplayNames = useSelector((state) => editionDisplayNames(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _offers = useSelector((state) => offers(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));

    const [displayDate, setDisplayDate] = useState('');
    const [displayEditions, setDisplayEditions] = useState('');

    useEffect(() => {
        console.log("DataCard useEffect _orderPriceResponse = " + JSON.stringify(_orderPriceResponse));
        if (_orderPriceResponse?.value) {
            var orderPrice = _orderPriceResponse.value;
            console.log(orderPrice?.effectiveDates);
            var displayDate = orderPrice?.effectiveDates?.map(eDate => format(new Date(eDate), formatMap[i18n.language], { locale: localeMap[i18n.language] }))
                .join(DataDivider(DEVICE_DESKTOP));
            //                .join(" \u2022 ");
            console.log("displayDate = " + displayDate)
            setDisplayDate(displayDate);
            var displayEditions = orderPrice?.effectiveEditionNames?.join(DataDivider(DEVICE_DESKTOP));
            setDisplayEditions(displayEditions);
        }
    }, [_orderPriceResponse, i18n.language]);


    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('schedule.dataCardTitle')} />
            <CardContent sx={{ pt: 4, height: height - 40 }}>
                <Box
                    sx={{
                        mb: 2,
                        height: '100%'
                    }}
                >
                    <Grid container width='100%' spacing='10'>
                        <Grid item xs={4} >
                            <Label text='order.dates' />
                        </Grid>
                        <Grid item xs={8}>
                            <DataBold text={displayDate ? displayDate : ''} />
                        </Grid>

                        <Grid item xs={4} >
                            <Label text='order.editions' />
                        </Grid>
                        <Grid item xs={8}>
                            <DataBold text={_editionDisplayNames ? _editionDisplayNames : ''} />
                        </Grid>

                        <Grid item xs={4} >
                            <Label text='order.offer' />
                        </Grid>
                        <Grid item xs={8}>
                            <Stack direction='row'>
                                <DataBold text={_selectedOffer ? _selectedOffer.name : 'xxx'} />
                                {!_orderToEdit && _offers.length > 1 && (
                                    <Button onClick={() => navigate(getPage('offers', DEVICE_DESKTOP))}>
                                        <EditIcon size='sm' />
                                    </Button>
                                )}
                            </Stack>
                        </Grid>

                    </Grid>
                </Box>
            </CardContent >
        </Card >
    );

}