import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, Dialog, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { DataBold, Label } from '../common/Commons';
import { PdfIcon } from '../common/DesktopIcons';

export const OrderFailureDialog = ({ open, handleClose, message, apiResponse }) => {

    const { t } = useTranslation();

    const [msg, setMsg] = useState(t('order.orderFailure'));

    useEffect(() => {
        setMsg(message ? message : t('order.orderFailure'));
    }, [message]);

    const getMessage = () => {
        return apiResponse?.originalRequest ? t('order.orderFailure.message') : t('order.orderFailure');
    };

    const orderDetails = () => {
        return (
            <Grid container spacing={5} sx={{ maxWidth: '50%', margin: 'auto' }}>
                {apiResponse.originalRequest.orderCode && (<Grid item xs={6} >
                    <Label text='order.orderCode' />
                </Grid>)}
                {apiResponse.originalRequest.orderCode && (<Grid item xs={6}>
                    <DataBold text={apiResponse.originalRequest.orderCode} />
                </Grid>)}
                <Grid item xs={6} >
                    <Label text='customerdata.businessPartnerNo' />
                </Grid>
                <Grid item xs={6}>
                    <DataBold text={apiResponse.originalRequest.businessPartnerNumber} />
                </Grid>
                <Grid item xs={6} >
                    <Label text='order.orderFailure.date' />
                </Grid>
                <Grid item xs={6}>
                    <DataBold text={new Date().toTimeString()} />
                </Grid>
            </Grid>
        );
    };

    const handleDownload = () => {
        document.getElementById('motif-download').click();
    };

    const Download = () => {
        return (<a style={{ visibility: "hidden" }}
            id="motif-download"
            rel="noreferrer"
            href={apiResponse.props["motif-download"]}
            target="_blank"
            download={`${apiResponse.originalRequest.motif.motifUuid}.pdf`}>
        </a>);
    }

    return (
        <Dialog fullScreen open={open}>
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <Typography
                    sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold' }}>{t('order.orderFailure.title')}</Typography>
                <Typography
                    sx={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: getMessage() }}></Typography>
                <Box sx={{ marginTop: '5vh' }} >
                    {apiResponse?.originalRequest && orderDetails()}
                </Box>
                <Stack direction="row" spacing={10} sx={{ marginTop: '10vh' }}>
                    <Button ssx={{ textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>Ok</Button>
                    {apiResponse?.originalRequest && <>
                        <Download></Download>
                        <Button sx={{ textTransform: 'none' }} startIcon={<PdfIcon />} variant="navigationBack" onClick={handleDownload}>{t('order.motifDownload')}</Button>
                    </>}
                </Stack>
            </Paper >
        </Dialog >
    );
}
