import React from 'react';
import clsx from "clsx";
import { useSelector } from 'react-redux';
import { device } from '../redux/Selectors';
import {
    MobileDropDownIcon, MobilePenIcon, MobileEyeIcon, MobileEyeSlashIcon,
    MobileTrashCanIcon, MobileClearIcon, MobileSwiperPrevIcon, MobileSwiperNextIcon,
    MobileCollapseDownIcon, MobileCollapseUpIcon, MobileHelpIcon, MobileInfoIcon,
    MobileSettingsIcon, MobileLoginIcon, MobileLogoutIcon, MobileUserIcon, MobileStarIcon,
    MobileAddIcon, MobileRadioButtonCheckedIcon, MobileRadioButtonUncheckedIcon,
    MobileHomeIcon, MobileLockIcon, MobileUserShieldIcon, MobileAddressBookIcon,
    MobileMoneyBillIcon, MobileRectangleAdIcon, MobileFileInvoiceIcon, MobileBoxIcon,
    MobileScribbleIcon, MobileListIcon, MobileReleaseIcon, MobilePdfIcon, MobileDeleteIcon,
    MobileEditIcon, MobileRepeatIcon, MobileMoreIcon, MobileGridViewIcon, MobileTableViewIcon,
    MobileMotifEditIcon, MobileCheckIcon, MobileDownloadIcon, MobileEnlargeIcon,
    MobileAccessibilityIcon, MobilePrintIcon
} from '../m/components/MobileIcons.jsx';
import {
    DropDownIcon, PenIcon, EyeIcon as DesktopEyeIcon, EyeSlashIcon as DesktopEyeSlashIcon,
    TrashCanIcon as DesktopTrashCanIcon, ClearIcon as DesktopClearIcon, SwiperPrevIcon as
        DesktopSwiperPrevIcon, SwiperNextIcon as DesktopSwiperNextIcon, CollapseDownIcon as
        DesktopCollapseDownIcon, CollapseUpIcon as DesktopCollapseUpIcon, HelpIcon as DesktopHelpIcon,
    InfoIcon as DesktopInfoIcon, SettingsIcon as DesktopSettingsIcon, LoginIcon as DesktopLoginIcon,
    LogoutIcon as DesktopLogoutIcon, UserIcon, StarIcon as DesktopStarIcon, AddIcon as DesktopAddIcon,
    RadioButtonCheckedIcon as DesktopRadioButtonCheckedIcon, RadioButtonUncheckedIcon as
        DesktopRadioButtonUncheckedIcon, HomeIcon as DesktopHomeIcon, LockIcon as DesktopLockIcon,
    UserShieldIcon, AddressBookIcon, MoneyBillIcon, RectangleAdIcon, FileInvoiceIcon, BoxIcon,
    ScribbleIcon, ListIcon as DesktopListIcon, ReleaseIcon as DesktopReleaseIcon, PdfIcon as
        DesktopPdfIcon, DeleteIcon as DesktopDeleteIcon, EditIcon as DesktopEditIcon, RepeatIcon as
        DesktopRepeatIcon, MoreIcon as DesktopMoreIcon, GridViewIcon as DesktopGridViewIcon,
    TableViewIcon as DesktopTableViewIcon, MotifEditIcon as DesktopMotifEditIcon, CheckIcon as DesktopCheckIcon,
    DownloadIcon as DesktopDownloadIcon, EnlargeIcon as DesktopEnlargeIcon, LinkIcon, AccessibilityIcon as DesktopAccessibilityIcon,
    PrintIcon as DesktopPrintIcon,
} from '../components/common/DesktopIcons.jsx';
import { styled } from '@mui/system';
import { DEVICE_ACC, DEVICE_DESKTOP } from './navigationTools.js';

const iconStyles = (theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    formControl: {
        margin: theme.spacing,
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing * 2
    },
    selectIcon: {
        pointerEvents: 'none', // Don't block pointer events on the select under the icon.
        color: theme.primary,
        '&$disabled': {
            color: theme.primary,
        }
    }
});

const StyledDiv = styled('div')(iconStyles);

export const CustomExpandMore = ({ className, classes, theme, ...rest }) => {
    return (
        <StyledDiv className={clsx(className, classes?.selectIcon)} >
            <SelectIconComponent
                {...rest}
            />
        </StyledDiv>
    );
}



export const SelectIconComponent = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileDropDownIcon {...props} />);
    } else {
        return (<DropDownIcon {...props} />);
    }
}

export const EditIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobilePenIcon {...props} />);
    } else {
        return (<PenIcon {...props} />);
    }
}

export const EyeIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileEyeIcon {...props} />);
    } else {
        return (<DesktopEyeIcon {...props} />);
    }
}

export const EyeSlashIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileEyeSlashIcon {...props} />);
    } else {
        return (<DesktopEyeSlashIcon {...props} />);
    }
}

export const TrashCanIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileTrashCanIcon {...props} />);
    } else {
        return (<DesktopTrashCanIcon {...props} />);
    }
}

export const ClearIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileClearIcon {...props} />);
    } else {
        return (<DesktopClearIcon {...props} />);
    }
}

export const SwiperPrevIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileSwiperPrevIcon {...props} />);
    } else {
        return (<DesktopSwiperPrevIcon {...props} />);
    }
}

export const SwiperNextIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileSwiperNextIcon {...props} />);
    } else {
        return (<DesktopSwiperNextIcon {...props} />);
    }
}

export const CollapseUpIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileCollapseUpIcon {...props} />);
    } else {
        return (<DesktopCollapseUpIcon {...props} />);
    }
}

export const CollapseDownIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileCollapseDownIcon {...props} />);
    } else {
        return (<DesktopCollapseDownIcon {...props} />);
    }
}

export const HelpIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileHelpIcon {...props} />);
    } else {
        return (<DesktopHelpIcon {...props} />);
    }
}

export const InfoIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileInfoIcon {...props} />);
    } else {
        return (<DesktopInfoIcon {...props} />);
    }
}

export const SettingsIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileSettingsIcon {...props} />);
    } else {
        return (<DesktopSettingsIcon {...props} />);
    }
}

export const LoginIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileLoginIcon {...props} />);
    } else {
        return (<DesktopLoginIcon {...props} />);
    }
}

export const LogoutIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileLogoutIcon {...props} />);
    } else {
        return (<DesktopLogoutIcon {...props} />);
    }
}

export const AccountIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileUserIcon {...props} />);
    } else {
        return (<UserIcon {...props} />);
    }
}

export const StarIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileStarIcon {...props} />);
    } else {
        return (<DesktopStarIcon {...props} />);
    }
}

export const AddIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileAddIcon {...props} />);
    } else {
        return (<DesktopAddIcon {...props} />);
    }
}

export const RadioButtonCheckedIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileRadioButtonCheckedIcon {...props} />);
    } else {
        return (<DesktopRadioButtonCheckedIcon {...props} />);
    }
}

export const RadioButtonUncheckedIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileRadioButtonUncheckedIcon {...props} />);
    } else {
        return (<DesktopRadioButtonUncheckedIcon {...props} />);
    }
}

export const ContactMailIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileUserIcon {...props} />);
    } else {
        return (<UserIcon {...props} />);
    }
}

export const HomeIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileHomeIcon {...props} />);
    } else {
        return (<DesktopHomeIcon {...props} />);
    }
}

export const LockIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileLockIcon {...props} />);
    } else {
        return (<DesktopLockIcon {...props} />);
    }
}

export const AccountBoxIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileUserShieldIcon {...props} />);
    } else {
        return (<UserShieldIcon {...props} />);
    }
}

export const ContactIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileAddressBookIcon {...props} />);
    } else {
        return (<AddressBookIcon {...props} />);
    }
}

export const PaymentsIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileMoneyBillIcon {...props} />);
    } else {
        return (<MoneyBillIcon {...props} />);
    }
}

export const OrdersIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileRectangleAdIcon {...props} />);
    } else {
        return (<RectangleAdIcon {...props} />);
    }
}

export const InvoiceIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileFileInvoiceIcon {...props} />);
    } else {
        return (<FileInvoiceIcon {...props} />);
    }
}

export const ServicePackageIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileBoxIcon {...props} />);
    } else {
        return (<BoxIcon {...props} />);
    }
}

export const AdvertizersIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileUserIcon {...props} />);
    } else {
        return (<UserShieldIcon {...props} />);
    }
}

export const DraftsIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileScribbleIcon {...props} />);
    } else {
        return (<ScribbleIcon {...props} />);
    }
}

export const ListIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileListIcon {...props} />);
    } else {
        return (<DesktopListIcon {...props} />);
    }
}

export const ReleaseIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileReleaseIcon {...props} />);
    } else {
        return (<DesktopReleaseIcon {...props} />);
    }
}

export const PdfIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobilePdfIcon {...props} />);
    } else {
        return (<DesktopPdfIcon {...props} />);
    }
}

export const CancelIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileDeleteIcon {...props} />);
    } else {
        return (<DesktopDeleteIcon {...props} />);
    }
}

export const OrderEditIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileEditIcon {...props} />);
    } else {
        return (<DesktopEditIcon {...props} />);
    }
}

export const MotifEditIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileMotifEditIcon {...props} />);
    } else {
        return (<DesktopMotifEditIcon {...props} />);
    }
}

export const RedoIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileRepeatIcon {...props} />);
    } else {
        return (<DesktopRepeatIcon {...props} />);
    }
}

export const MoreIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileMoreIcon {...props} />);
    } else {
        return (<DesktopMoreIcon {...props} />);
    }
}

export const GridViewIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileGridViewIcon {...props} />);
    } else {
        return (<DesktopGridViewIcon {...props} />);
    }
}

export const TableViewIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileTableViewIcon {...props} />);
    } else {
        return (<DesktopTableViewIcon {...props} />);
    }
}

export const CheckIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileCheckIcon {...props} />);
    } else {
        return (<DesktopCheckIcon {...props} />);
    }
}

export const DownloadIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileDownloadIcon {...props} />);
    } else {
        return (<DesktopDownloadIcon {...props} />);
    }
}

export const EnlargeIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileEnlargeIcon {...props} />);
    } else {
        return (<DesktopEnlargeIcon {...props} />);
    }
}

export const DeepLinkIcon = (props) => {
    return (<LinkIcon {...props} />);
}

export const AccessibilityIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobileAccessibilityIcon {...props} />);
    } else {
        return (<DesktopAccessibilityIcon {...props} />);
    }
}

export const PrintIcon = (props) => {

    const _device = useSelector((state) => device(state));

    if (_device === 'mobile') {
        return (<MobilePrintIcon {...props} />);
    } else {
        return (<DesktopPrintIcon {...props} />);
    }
}

export const DataDivider = (device) => {
    // const _device = useSelector((state) => device(state));

    if (device === DEVICE_DESKTOP) {
        return " + ";
    } else if (device === DEVICE_ACC) {
        return " \u2022 "
    } else {
        return " \u2022 "
    }

}

