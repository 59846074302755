export const styleForm = {
    justifyContent: 'left',
    alignItems: 'left',
    padding: 2,

    '& .MuiTextField-root': {
        margin: 5,
        m: 3,
        width: 400
    },
    '& .MuiSelect-root': {
        margin: 1,
        m: 3
    },
    '& .MuiButtonBase-root': {
        margin: 1,
        width: 200,
        m: 3
    },
    '& .MuiBox-root': {
        width: 300
    },
    '& .MuiFormControlLabel-root': {
        alignItems: 'start'
    }
}
