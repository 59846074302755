import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    authentication,
    businessPartner,
    customerPageTab,
    bpResponse,
    features,
    isSsoAuthentication,
    servicePackagesCount,
    isWSS
} from '../redux/Selectors.js';
import {
    saveBusinessPartner,
    setCustomerTab,
    resetBpResponse
} from '../redux/Actions';

import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import {
    Alert,
    Backdrop,
    Box,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Paper,
    Stack,
    Tab,
    tabsClasses,
    Snackbar,
    Typography
} from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';

import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import Home from '../components/customer/Home';
import { MailAddress } from '../components/customer/MailAddress';
import { Account } from '../components/customer/Account';
import { Contact } from '../components/customer/Contact';
import { Payment } from '../components/customer/Payment';
import { Orders } from '../components/customer/Orders';
import { Drafts } from '../components/customer/Drafts.jsx';
import { Advertizers } from '../components/customer/Advertizers.jsx';
import { Invoices } from '../components/customer/Invoices';
import { ServicePackages } from '../components/customer/ServicePackages';
import { MessageBox } from '../components/ModalDialog';

import InfoText from '../components/InfoText';

import {
    ContactMailIcon, HomeIcon, LockIcon, AccountBoxIcon, ContactIcon,
    PaymentsIcon, OrdersIcon, InvoiceIcon, ServicePackageIcon, DraftsIcon, AdvertizersIcon
} from '../common/widgetTools.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Customer = (props) => {
    const navigate = useNavigate();
    const { tab } = useParams()
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const refAccount = useRef(null);
    const refAddress = useRef(null);
    const refContact = useRef(null);

    const _authentication = useSelector((state) => authentication(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _servicePackagesCount = useSelector((state) => servicePackagesCount(state));
    const _bpResponse = useSelector((state) => bpResponse(state));
    const _features = useSelector((state) => features(state));
    const _isSsoAuthentication = useSelector((state) => isSsoAuthentication(state));
    const _isWSS = useSelector((state) => isWSS(state));

    const [activeTab, setActiveTab] = useState('home');
    const _customerPageTab = useSelector((state) => customerPageTab(state));
    const [modified, setModified] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [height, setHeight] = useState('auto');

    const [mailAddressProtectedFromChange, setMailAddressProtectedFromChange] = useState(false);
    const [mailAddressDirty, setMailAddressDirty] = useState(false);
    const [accountDirty, setAccountDirty] = useState(false);
    const [contactDirty, setContactDirty] = useState(false);
    const [paymentDirty, setPaymentDirty] = useState(false);
    const [isAddressValid, setIsAddressValid] = useState(true);
    const [isContactValid, setIsContactValid] = useState(true);
    const [isAccountValid, setIsAccountValid] = useState(true);
    const [saveMsg, setSaveMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [directDebitOpen, setDirectDebitOpen] = useState(false);

    const WIDTH = "90%";

    useEffect(() => {
        if (tab) {
            setActiveTab(tab);
        }
    }, [tab])

    const listenResize = () => {
        setHeight(window.innerHeight - 235);
    };

    useEffect(() => {
        listenResize();
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        if (_authentication && _businessPartner) {
            const bp = JSON.parse(JSON.stringify(_businessPartner));
            bp.credentials = { name: _authentication.userName, password: '' };
            setModified(bp);
            setMailAddressProtectedFromChange(_businessPartner.legalEntity.mailAddresses[0].isProtectedFromChange);
            setMailAddressDirty(false);
            setAccountDirty(false);
            setContactDirty(false);
            setPaymentDirty(false);
        }
    }, [_authentication, _businessPartner]);

    useEffect(() => {
        if (_bpResponse) {
            if (_bpResponse.success) {
                setSaveMsg(t('customerdata.updateSuccess'));
                setSnackbarSeverity('info');
            } else {
                if (_bpResponse.errors && _bpResponse.errors[0] && _bpResponse.errors[0].text && _bpResponse.errors[0].text !== "") {
                    setSaveMsg(_bpResponse.errors[0].text);
                    setSnackbarSeverity('error');
                }
            }
            setSnackbarOpen(true);
            setBackdropOpen(false);
            dispatch(resetBpResponse());
        }
    }, [_bpResponse]);

    useEffect(() => {
        console.log("isAccountValid = " + isAccountValid);
        console.log("isAddressValid = " + isAddressValid);
        console.log("isContactValid = " + isContactValid);
    }, [isAccountValid, isAddressValid, isContactValid])


    const handleChange = (event, newValue) => {
        changeTab(newValue);
    };

    function changeTab(tab) {
        // navigate(`/webstore/app/customer/${tab}`);
        dispatch(setCustomerTab(tab));
        //        setActiveTab(tab);
    }

    useEffect(() => {
        setActiveTab(_customerPageTab);
    }, [_customerPageTab]);



    const closeHandler = () => {
        navigate(-1);
    };

    const hasActiveBankaccounts = (bp) => {
        if (bp.bankAccounts.length === 0) {
            return false;
        }
        let deleted = bp.bankAccounts.filter(it => it._deleted);
        if (deleted.length === bp.bankAccounts.length) {
            return false;
        }
        return true;
    }

    const save = () => {
        setBackdropOpen(true);
        if (accountDirty) {
            modified.legalEntity.credentials = modified.credentials;
        }
        if (modified.commercialProps[0].preferredPaymentMethod === "paymentDirectDebit" && !hasActiveBankaccounts(modified)) {
            setDirectDebitOpen(true);
            setBackdropOpen(false);
            return;
        }
        dispatch(saveBusinessPartner(modified, _businessPartner.businessPartnerNo));
    };

    const cancel = () => {
        if (isDirty()) {
            setDirtyDialogOpen(true);
        }
        else {
            closeHandler();
        }
    };

    const isDirty = () => {
        return mailAddressDirty || accountDirty || contactDirty || paymentDirty;
    };

    const faIconStyle = {
        width: '100%',
        height: 30,
        marginLeft: 2,
        paddingRight: 2
    };

    const tabIconStyle = () => {
        return {
            flex: 1,
            width: '100%',
            mt: 0,
            pt: 0.5,
            ml: 1,
            pb: 0,
            pr: 1,
            height: 30,
            '& .MuiButtonBase-root': {
                p: 0,
                maxWidth: 30,
                maxHeight: 30
            }
        }
    };


    const tabButtonStyle = () => {
        return {
            mt: 3,
            pt: 0,
            ml: 0,
            pb: 3,
            minHeight: 50,
            '& .MuiButtonBase-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20
            },
            '& .MuiButtonBase-root-MuiTab-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20
            },
            '& .MuiBadge-root': {
                maxHeight: 20,
                minWidth: 20
            }

        }
    };

    const tabLabel = (key, dirty = false) => {
        return (<Typography variant="customerTabButton"> {dirty ? '* ' : ''}{t(key)}</Typography>)
    };

    const showSaveButton = () => {
        return ('address' === activeTab || 'contact' === activeTab || 'account' === activeTab || 'payment' === activeTab
            || isDirty());
    };

    // ------------------------------
    //
    // ------------------------------
    const [dirtyDialogOpen, setDirtyDialogOpen] = useState(false);
    const handledDirtyDialogClose = leaveUnsaved => {
        if (leaveUnsaved) {
            closeHandler();
        }
        setDirtyDialogOpen(false);
    };

    const mailAddressChanged = () => {
        let a = JSON.stringify(modified.legalEntity.mailAddresses[0]);
        let b = JSON.stringify(_businessPartner.legalEntity.mailAddresses[0]);
        setMailAddressDirty(a !== b);
    };

    const accountChanged = () => {
        const org = { name: _authentication.userName, password: '' };
        let a = JSON.stringify(modified.credentials);
        let b = JSON.stringify(org);
        setAccountDirty(a !== b);
    };

    const contactChanged = () => {
        let a = JSON.stringify(modified.legalEntity.netAddresses[0]);
        let b = JSON.stringify(_businessPartner.legalEntity.netAddresses[0]);
        setContactDirty(a !== b);
    };

    const paymentChanged = () => {
        let a = JSON.stringify(modified.commercialProps[0]);
        let b = JSON.stringify(_businessPartner.commercialProps[0]);
        setPaymentDirty(a !== b);
    };

    const DirtyDialog = () => {
        return (
            <Dialog
                open={dirtyDialogOpen}
                onClose={() => { handledDirtyDialogClose(false) }}
            >
                <Alert severity="warning">
                </Alert>
                <DialogTitle>
                    {t('customerdata.home.dirty.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('customerdata.home.dirty.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} variant="navigationBack" onClick={() => { handledDirtyDialogClose(true) }}>{t('common.yes')}</Button>
                    <Button sx={{ textTransform: 'none' }} variant="navigationAction" onClick={() => { handledDirtyDialogClose(false) }} autoFocus>
                        {t('common.no')}
                    </Button>
                </DialogActions>
            </Dialog>)
    };

    const Action1 = () => {
        return <Button variant="outlined" onClick={cancel}>{t('customerdataPage.close')}</Button>;
    }

    const Action2 = () => {
        return showSaveButton() ? (<Button variant="contained" disabled={!isAccountValid} onClick={save}> {t('customerdataPage.adobt')}</Button>) : null;
    }

    return (
        modified ? (
            <Stack direction="column" spacing={5}>
                <AppHeader></AppHeader>

                <Box >
                    <Paper sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }} elevation={2} >
                        <TabContext value={activeTab}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <TabList sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                    mt: 0,
                                    pt: 0,
                                    height: 30

                                }}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    value={activeTab}
                                    onChange={handleChange} >
                                    <Tab
                                        label={tabLabel('customerdata.home.title')}
                                        value='home'
                                        iconPosition='start'
                                        // icon={<HomeIcon sx={tabIconStyle} />}
                                        //icon={<FontAwesomeIcon icon={solid('home')} style={faIconStyle} />}
                                        // icon={<SvgIcon component={<FontAwesomeIcon icon={solid('home')} style={faIconStyle} />} inheritViewBox />}
                                        icon={<Box sx={tabIconStyle}><HomeIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>
                                    {!_isSsoAuthentication && (<Tab
                                        label={tabLabel('customerdata.account.title', accountDirty)}
                                        value='account'
                                        iconPosition='start'
                                        icon={<Box sx={tabIconStyle}><AccountBoxIcon /></Box>} sx={tabButtonStyle}
                                    >
                                    </Tab>)}
                                    <Tab
                                        label={tabLabel('customerdata.address.title', mailAddressDirty)}
                                        value='address'
                                        iconPosition='start'
                                        icon={mailAddressProtectedFromChange ? <Box sx={tabIconStyle}><LockIcon /></Box> : <Box sx={tabIconStyle}><ContactMailIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>
                                    <Tab
                                        label={tabLabel('customerdata.contact.title', contactDirty)}
                                        value='contact'
                                        iconPosition='start'
                                        icon={<Box sx={tabIconStyle}><ContactIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>
                                    {!_isWSS && (<Tab
                                        label={tabLabel('customerdata.payment.title', paymentDirty)}
                                        value='payment'
                                        iconPosition='start'
                                        icon={<Box sx={tabIconStyle}><PaymentsIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>)}
                                    <Tab
                                        label={tabLabel('customerdata.orders.title')}
                                        value='orders'
                                        iconPosition='start'
                                        // icon={<Badge badgeContent={_orders.length} color="info" sx={tabBadgeStyle}><DescriptionIcon sx={tabIconStyle} /></Badge>}
                                        icon={<Box sx={tabIconStyle}><OrdersIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>
                                    {_features.ASE_1992.active && (<Tab
                                        label={tabLabel('customerdata.drafts.title')}
                                        value='drafts'
                                        iconPosition='start'
                                        icon={<Box sx={tabIconStyle}><DraftsIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>)}
                                    {!_isWSS && (<Tab
                                        label={tabLabel('customerdata.invoices.title')}
                                        value='invoices'
                                        iconPosition='start'
                                        // icon={<Badge badgeContent={_invoices.length} color="info" sx={tabBadgeStyle}><ReceiptIcon sx={tabIconStyle} /></Badge>}
                                        icon={<Box sx={tabIconStyle}><InvoiceIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>)}  
                                    {_servicePackagesCount && (<Tab
                                        label={tabLabel('customerdata.servicePackages.title')}
                                        value='servicePackages'
                                        iconPosition='start'
                                        icon={<Box sx={tabIconStyle}><ServicePackageIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>)}
                                    {_businessPartner?.isAgency && (<Tab
                                        label={tabLabel('customerdata.advertizers.title')}
                                        value='advertizers'
                                        iconPosition='start'
                                        icon={<Box sx={tabIconStyle}><AdvertizersIcon size='xl' /></Box>}
                                        sx={tabButtonStyle}
                                    >
                                    </Tab>)}
                                </TabList>
                                <Divider orientation="vertical" flexItem ></Divider>
                                <Typography sx={{ mr: '1rem', ml: '1rem' }} variant="pageTitleBold">{t('customerdata.home.description')}</Typography>
                            </Stack>
                            <Divider />

                            <Box sx={{ height: height, overflowY: 'scroll' }}>
                                <TabPanel value='home'>
                                    <Home changeTab={changeTab}></Home>
                                </TabPanel>
                                <TabPanel value='address'>
                                    <MailAddress
                                        mailAddress={modified.legalEntity.mailAddresses[0]}
                                        legalEntity={modified.legalEntity}
                                        dirty={mailAddressChanged}
                                        valid={setIsAddressValid}>
                                    </MailAddress>
                                </TabPanel>
                                {!_isSsoAuthentication && (<TabPanel value='account'>
                                    <Account
                                        credentials={modified.credentials}
                                        marketingApproval={modified.marketingApproval}
                                        dirty={accountChanged}
                                        bpNew={false}
                                        valid={setIsAccountValid}>
                                    </Account>
                                </TabPanel>)}
                                <TabPanel value='contact'>
                                    <Contact
                                        _teleComms={modified.legalEntity.teleComms}
                                        _netAddress={modified.legalEntity.netAddresses[0]}
                                        dirty={contactChanged}
                                        valid={setIsContactValid}>
                                    </Contact>
                                </TabPanel>
                                {!_isWSS && (<TabPanel value='payment'>
                                    <Payment
                                        commercialProps={modified.commercialProps[0]}
                                        businessPartner={modified}
                                        dirty={paymentChanged}>
                                    </Payment>
                                </TabPanel>)}
                                <TabPanel value='orders'><Orders
                                    showBusyIndicator={setBackdropOpen}
                                /></TabPanel>
                                {!_isWSS && (<TabPanel value='invoices'><Invoices
                                    showBusyIndicator={setBackdropOpen}
                                /></TabPanel>)}
                                <TabPanel value='servicePackages'><ServicePackages
                                    showBusyIndicator={setBackdropOpen} servicePackages={modified.servicePackages} /></TabPanel>
                                <TabPanel value='drafts'><Drafts
                                    showBusyIndicator={setBackdropOpen}
                                /></TabPanel>
                                <TabPanel value='advertizers'><Advertizers
                                    showBusyIndicator={setBackdropOpen}
                                /></TabPanel>
                            </Box>
                        </TabContext >
                    </Paper >
                </Box>

                <AppFooter
                    title={'customerdata.home.description'}
                    action1={<Action1 />}
                    action2={<Action2 />}
                />
                <InfoText />

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => {
                        setSnackbarOpen(false);
                        // closeHandler();
                    }}
                >
                    <Alert severity={snackbarSeverity}>{saveMsg}</Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdropOpen}
                    onClick={() => {
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DirtyDialog></DirtyDialog>
                <MessageBox open={directDebitOpen} handleClose={() => setDirectDebitOpen(false)} message={'order.accountRequired'} severity="error"></MessageBox>

            </Stack >) : <></>
    );

}

export default Customer;

