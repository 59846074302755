import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown, faPen, faEye, faEyeSlash, faTrashCan, faXmark, faChevronRight,
    faChevronLeft, faChevronUp, faChevronDown, faCircleQuestion, faCircleInfo,
    faEllipsis, faRightToBracket, faRightFromBracket, faUser, faStar, faPlus,
    faCheckCircle, faHome, faLockKeyhole, faUserShield, faAddressBook, faMoneyBill,
    faRectangleAd, faFileInvoice, faBox, faScribble, faList, faCircleCheck, faFilePdf,
    faRepeat, faXmarkCircle, faGrid, faTable, faPenToSquare, faCheck, faMagnifyingGlassPlus,
    faUniversalAccess, faPrint
} from '@fortawesome/pro-regular-svg-icons';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-duotone-svg-icons';
/**
 * 
 * @param {*} props 
 * @returns 
 */

export const MobileDropDownIcon = (props) => {
    return (< FontAwesomeIcon icon={faCaretDown} size={props.size ? props.size : 'lg'} />);
}

export const MobilePenIcon = (props) => {
    return (< FontAwesomeIcon icon={faPen} size={props.size ? props.size : 'xs'} />);
}

export const MobileEyeIcon = (props) => {
    return (< FontAwesomeIcon icon={faEye} size={props.size ? props.size : 'xs'} />);
}

export const MobileEyeSlashIcon = (props) => {
    return (< FontAwesomeIcon icon={faEyeSlash} size={props.size ? props.size : 'xs'} />);
}

export const MobileTrashCanIcon = (props) => {
    return (< FontAwesomeIcon icon={faTrashCan} size={props.size ? props.size : 'xs'} />);
}

export const MobileClearIcon = (props) => {
    return (< FontAwesomeIcon icon={faXmark} size={props.size ? props.size : 'xs'} />);
}

export const MobileSwiperNextIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronRight} size={props.size ? props.size : 'sm'} />);
}

export const MobileSwiperPrevIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronLeft} size={props.size ? props.size : 'sm'} />);
}

export const MobileCollapseUpIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronUp} size={props.size ? props.size : 'sm'} />);
}

export const MobileCollapseDownIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronDown} size={props.size ? props.size : 'sm'} />);
}

export const MobileHelpIcon = (props) => {
    return (< FontAwesomeIcon icon={faCircleQuestion} size={props.size ? props.size : 'xs'} />);
}

export const MobileInfoIcon = (props) => {
    return (< FontAwesomeIcon icon={faCircleInfo} size={props.size ? props.size : 'sm'} />);
}

export const MobileSettingsIcon = (props) => {
    return (< FontAwesomeIcon icon={faEllipsis} size={props.size ? props.size : 'lg'} />);
}

export const MobileLoginIcon = (props) => {
    return (< FontAwesomeIcon icon={faRightToBracket} size={props.size ? props.size : 'sm'} />);
}

export const MobileLogoutIcon = (props) => {
    return (< FontAwesomeIcon icon={faRightFromBracket} size={props.size ? props.size : 'sm'} />);
}

export const MobileUserIcon = (props) => {
    return (< FontAwesomeIcon icon={faUser} size={props.size ? props.size : 'sm'} />);
}

export const MobileStarIcon = (props) => {
    return (< FontAwesomeIcon icon={faStar} size={props.size ? props.size : 'sm'} />);
}

export const MobileAddIcon = (props) => {
    return (< FontAwesomeIcon icon={faPlus} size={props.size ? props.size : 'lg'} />);
}

export const MobileRadioButtonCheckedIcon = (props) => {
    return (< FontAwesomeIcon icon={faCheckCircle} size={props.size ? props.size : 'lg'} />);
}

export const MobileRadioButtonUncheckedIcon = (props) => {
    return (< FontAwesomeIcon icon={faCircle} size={props.size ? props.size : 'lg'} />);
}

export const MobileHomeIcon = (props) => {
    return (< FontAwesomeIcon icon={faHome} size={props.size ? props.size : 'lg'} />);
}

export const MobileLockIcon = (props) => {
    return (<FontAwesomeIcon icon={faLockKeyhole} size={props.size ? props.size : 'xl'} />);
}

export const MobileUserShieldIcon = (props) => {
    return (<FontAwesomeIcon icon={faUserShield} size={props.size ? props.size : 'xl'} />);
}

export const MobileAddressBookIcon = (props) => {
    return (<FontAwesomeIcon icon={faAddressBook} size={props.size ? props.size : 'xl'} />);
}

export const MobileMoneyBillIcon = (props) => {
    return (<FontAwesomeIcon icon={faMoneyBill} size={props.size ? props.size : 'xl'} />);
}

export const MobileRectangleAdIcon = (props) => {
    return (<FontAwesomeIcon icon={faRectangleAd} size={props.size ? props.size : 'xl'} />);
}

export const MobileFileInvoiceIcon = (props) => {
    return (<FontAwesomeIcon icon={faFileInvoice} size={props.size ? props.size : 'xl'} />);
}

export const MobileBoxIcon = (props) => {
    return (<FontAwesomeIcon icon={faBox} size={props.size ? props.size : 'xl'} />);
}

export const MobileScribbleIcon = (props) => {
    return (<FontAwesomeIcon icon={faScribble} size={props.size ? props.size : 'xl'} />);
}

export const MobileListIcon = (props) => {
    return (<FontAwesomeIcon icon={faList} size={props.size ? props.size : 'sm'} />);
}

export const MobileReleaseIcon = (props) => {
    return (<FontAwesomeIcon icon={faCircleCheck} size={props.size ? props.size : 'sm'} />);
}

export const MobilePdfIcon = (props) => {
    return (<FontAwesomeIcon icon={faFilePdf} size={props.size ? props.size : 'sm'} />);
}

export const MobileRepeatIcon = (props) => {
    return (<FontAwesomeIcon icon={faRepeat} size={props.size ? props.size : 'sm'} />);
}

export const MobileDeleteIcon = (props) => {
    return (<FontAwesomeIcon icon={faXmarkCircle} size={props.size ? props.size : 'sm'} />);
}

export const MobileEditIcon = (props) => {
    return (<FontAwesomeIcon icon={faPen} size={props.size ? props.size : 'sm'} />);
}

export const MobileMotifEditIcon = (props) => {
    return (<FontAwesomeIcon icon={faPenToSquare} size={props.size ? props.size : 'sm'} />);
}

export const MobileMoreIcon = (props) => {
    return (<FontAwesomeIcon icon={faEllipsis} size={props.size ? props.size : 'sm'} />);
}

export const MobileGridViewIcon = (props) => {
    return (<FontAwesomeIcon icon={faGrid} size={props.size ? props.size : 'sm'} />);
}

export const MobileTableViewIcon = (props) => {
    return (<FontAwesomeIcon icon={faTable} size={props.size ? props.size : 'sm'} />);
}

export const MobileCheckIcon = (props) => {
    return (<FontAwesomeIcon icon={faCheck} size={props.size ? props.size : 'sm'} />);
}

export const MobileDownloadIcon = (props) => {
    return (<FontAwesomeIcon icon={faDownload} size={props.size ? props.size : 'sm'} />);
}

export const MobileEnlargeIcon = (props) => {
    return (<FontAwesomeIcon icon={faMagnifyingGlassPlus} size={props.size ? props.size : 'sm'} />);
}

export const MobileAccessibilityIcon = (props) => {
    return (<FontAwesomeIcon icon={faUniversalAccess} size={props.size ? props.size : '2xl'} />);
}

export const MobilePrintIcon = (props) => {
    return (<FontAwesomeIcon icon={faPrint} size={props.size ? props.size : 'sm'} />);
}