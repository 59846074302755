import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { Box, Button, Card, CardContent, Checkbox, FormControl, Grid, IconButton, MenuItem, Select, Stack, Tooltip, Typography, TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/material';
import HtmlTooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import AreaCardHeader from '../common/AreaCardHeader';
import { DesiredDatePicker } from './DesiredDatePicker';
import { useSelector, useDispatch } from 'react-redux';
import {
    pendingOrder, selectedMarket, selectedOffer, promotionalText, orderPriceResponse,
    editionDisplayNames, offers, tenant, rawText, features, hasAlternativeAddress,
    isCicAuthenticated, orderTypes, defaults, properties, selectedEditionPart, desiredDate
} from '../../redux/Selectors.js';
import {
    setOrderIsCombi, setHasAlternativeAddress, setOrderType, setOrderFixPrice,
    setPriceCalculation, setOrderComment, setVoucher
} from '../../redux/Actions.js';
import { EditionPartHierarchie } from './EditionPartHierarchie';
import format from 'date-fns/format';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import Collapse from '@mui/material/Collapse';
import { AlternativeContacts } from '../customer/AlternativeContacts';
import { PublicationDatePicker } from './PublicationDatePicker';
import { OfferTooltip } from '../common/Offer'
import { DataDivider } from '../../common/widgetTools.js';
import { DEVICE_DESKTOP } from '../../common/navigationTools.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderBookingCard = ({ layoutDisplayOfferAction, height, dataComplete }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [displayDate, setDisplayDate] = useState('');
    const [displayEditions, setDisplayEditions] = useState('');
    const [combiChecked, setCombiChecked] = useState(false);
    const [orderCatchword, setOrderCatchword] = useState('');
    const [ordType, setOrdType] = useState('');
    const [priceCalc, setPriceCalc] = useState('regular');
    const [fixPrice, setFixPrice] = useState('');
    const [isFixPrice, setIsFixPrice] = useState(false);
    const [ordComment, setOrdComment] = useState('');
    const [isOrderVoucherEnabled, setIsOrderVoucherEnabled] = useState(false);
    const [ordVoucherValue, setOrdVoucherValue] = useState('');
    const [isVoucherFieldEnabled, setIsVoucherFieldEnabled] = useState(true);
    const [isVoucherButtonEnabled, setIsVoucherButtonEnabled] = useState(false);

    const _pendingOrder = useSelector((state) => pendingOrder(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));
    const _promotionalText = useSelector((state) => promotionalText(state));
    const _editionDisplayNames = useSelector((state) => editionDisplayNames(state));
    const _offers = useSelector((state) => offers(state));
    const _tenant = useSelector((state) => tenant(state));
    const _rawText = useSelector((state) => rawText(state));
    const _features = useSelector((state) => features(state));
    const _hasAlternativeAddress = useSelector((state) => hasAlternativeAddress(state));
    const _isCicAuthenticated = useSelector((state) => isCicAuthenticated(state));
    const _orderTypes = useSelector((state) => orderTypes(state));
    const _defaults = useSelector((state) => defaults(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _desiredDate = useSelector((state) => desiredDate(state));

    useEffect(() => {
        // sapCombination
        var checked = _selectedOffer?.sapCombination && (_selectedOffer?.sapDefault || _selectedOffer?.sapForce);
        /*       if (this.orderToRepeat) {
                   if (this.pendingOrder && this.pendingOrder.orderIsCombi) {
                       checked = true;
                   }
               } */
        dispatch(setOrderIsCombi(checked));
        setCombiChecked(checked);

        // catchword
        setOrderCatchword(_rawText);

        // voucher
        setOrdVoucherValue(_defaults.voucher);
    }, []);

    useEffect(() => {
        setIsOrderVoucherEnabled(_selectedOffer?.orderVoucher !== undefined)
    }, [_selectedOffer]);

    useEffect(() => {
        setIsVoucherButtonEnabled(isVoucherFieldEnabled && Boolean(ordVoucherValue));
    }, [ordVoucherValue, isVoucherFieldEnabled])

    useEffect(() => {
        if (_pendingOrder) {
        }
    }, [_pendingOrder]);

    useEffect(() => {
        if (_orderPriceResponse) {
            var orderPrice = _orderPriceResponse.value;
            var displayDate = orderPrice?.effectiveDates?.map(eDate => format(new Date(eDate), formatMap[i18n.language], { locale: localeMap[i18n.language] }))
                .join(DataDivider(DEVICE_DESKTOP));
            //          .join(" \u2022 ");
            setDisplayDate(displayDate);
            var displayEditions = orderPrice?.effectiveEditionNames?.join(DataDivider(DEVICE_DESKTOP));
            setDisplayEditions(displayEditions);
        }
    }, [_orderPriceResponse, i18n.language]);

    useEffect(() => {
        if (_selectedOffer && _selectedEditionPart && _desiredDate && _orderPriceResponse && displayDate && displayDate !== '') {
            dataComplete(true);
        } else {
            dataComplete(false);
        }
    }, [_selectedOffer, _selectedEditionPart, _desiredDate, _orderPriceResponse, displayDate]);


    const handleCombiChange = (event) => {
        setCombiChecked(event.target.checked);
        dispatch(setOrderIsCombi(event.target.checked));
    };

    const handleCatchwordChange = (event) => {
        setOrderCatchword(event.target.value);
        dispatch(setOrderCatchword(event.target.value));
    };

    const handleOrderTypeChange = (event) => {
        console.log("changed orderType to " + event.target.value)
        setOrdType(event.target.value);
        dispatch(setOrderType(event.target.value));
    };

    const handlePriceCalculationChanged = (event) => {
        setPriceCalc(event.target.value);
        dispatch(setPriceCalculation(event.target.value));
        setIsFixPrice(event.target.value === "fix");
    }

    const handleOrderFixPriceChange = (event) => {
        setFixPrice(event.target.value);
        dispatch(setOrderFixPrice(event.target.value.replace(",", ".")));
    }

    const handleOrderCommentChange = (event) => {
        setOrdComment(event.target.value);
        dispatch(setOrderComment(event.target.value));
    }

    const handleVoucherChange = (event) => {
        setOrdVoucherValue(event.target.value);
        dispatch(setVoucher());
    }

    const cashVoucher = (event) => {
        setIsVoucherFieldEnabled(false);
        dispatch(setVoucher(ordVoucherValue));
    }

    const Label = ({ text }) => {
        return (
            <Typography
                sx={{ fontSize: 16 }}>
                {t(text)}:
            </Typography>
        );
    }

    const DataBold = ({ text }) => {
        return (
            <Typography variant='textBold'>
                {text}
            </Typography>
        );
    }


    const [showAltContacts, setShowAltContacts] = useState(false);
    const handleExpandAltAddr = () => {
        if (showAltContacts === true) {
            setShowAltContacts(false);
            dispatch(setHasAlternativeAddress(false));
        } else {
            setShowAltContacts(true);
            dispatch(setHasAlternativeAddress(true));
        }
    }

    const getStyleBold = () => {
        return (
            {
                fontFamily: 'ABeZehBold, sans-serif',
                fontSize: 16,
                color: '#191919',
                opacity: 1,
                letterSpacing: 0,
                fontWeight: 'bold'
            }
        );
    }



    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('order.bookingCardTitle')} />
            <CardContent>
                <Box
                    sx={{
                        mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: height,
                        overflow: "hidden",
                        overflowY: "scroll",
                        // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                    }}
                >
                    <FormControl sx={{ width: '100%' }}>
                        <Grid container width='100%' spacing='10'>
                            <Grid item xs={4} >
                                <Label text='order.category' />
                            </Grid>
                            <Grid item xs={8}>
                                <DataBold text={_selectedMarket ? _selectedMarket.name : 'xxx'} />
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.offer' />
                            </Grid>
                            <Grid item xs={8}>
                                <HtmlTooltip
                                    arrow
                                    placement="right-start"
                                    title={<OfferTooltip offer={_selectedOffer} />}
                                >
                                    <Stack direction="row"
                                        justifyContent="left"
                                        alignItems="center"
                                        spacing={2}>
                                        <DataBold text={_selectedOffer.name}></DataBold>
                                        <IconButton size='small' component="span">
                                            <InfoIcon />
                                        </IconButton>
                                    </Stack>
                                </HtmlTooltip>
                            </Grid>

                            <Grid item xs={4} />
                            <Grid item xs={8}>
                                {_offers?.length > 1 && (<Button variant='link' sx={{ pl: 0 }} onClick={() => layoutDisplayOfferAction()}>
                                    <HtmlTooltip
                                        arrow
                                        placement="right-start"
                                        title={
                                            <React.Fragment>
                                                <Stack
                                                    direction='column'
                                                    spacing='10'
                                                    sx={{ width: 200, m: 10 }}>
                                                    <Typography
                                                        sx={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left', color: '#F4F4F4' }}>
                                                        {t('order.offers.tooltip.title')}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ fontSize: 12, textAlign: 'left', color: '#F4F4F4' }}>
                                                        {t('order.offers.tooltip.text')}
                                                    </Typography>
                                                </Stack>
                                            </React.Fragment>
                                        }

                                    >
                                        <Typography sx={{ textDecoration: 'underline', fontSize: 14, ml: 0, pl: 0 }}>
                                            {t('offer.moreOffers', { count: _offers.length - 1 })}
                                        </Typography>
                                    </HtmlTooltip>
                                </Button>)}
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.editionPart' />
                            </Grid>
                            <Grid item xs={8}>
                                <EditionPartHierarchie styleSelect={getStyleBold} />
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.desired' />
                            </Grid>
                            <Grid item xs={8}>
                                {!_selectedOffer.publicationCalendar ? (<DesiredDatePicker styleSelect={getStyleBold} />) : (<PublicationDatePicker styleSelect={getStyleBold} />)}
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.dates' />
                            </Grid>
                            <Grid item xs={8}>
                                <DataBold text={displayDate ? displayDate : ''} />
                            </Grid>

                            {_selectedOffer?.sapCombination && (
                                <>
                                    <Grid item xs={4} >
                                        <Label text='order.sap.combination' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Checkbox
                                            checked={combiChecked}
                                            onChange={handleCombiChange}
                                            disabled={_selectedOffer?.sapCombination && _selectedOffer.sapForce}
                                            sx={{ p: 0 }} />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={4} >
                                <Label text='order.editions' />
                            </Grid>
                            <Grid item xs={8}>
                                <DataBold text={_editionDisplayNames ? _editionDisplayNames : ''} />
                            </Grid>

                            <Grid item xs={4} >
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 12 }} >
                                    {_promotionalText ? _promotionalText : ''}
                                </Typography>
                            </Grid>

                            {isOrderVoucherEnabled && (
                                <>
                                    <Grid item xs={4} >
                                        <Label text='admin.settings.orderVoucher' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Stack
                                            direction="row"
                                            spacing={4}
                                        >
                                            <TextField
                                                variant='standard'
                                                value={ordVoucherValue}
                                                onChange={handleVoucherChange}
                                                disabled={!isVoucherFieldEnabled}
                                                sx={{ width: 200 }} />

                                            <Button
                                                variant='outlined'
                                                onClick={cashVoucher}
                                                disabled={!isVoucherButtonEnabled}
                                                sx={{}} >
                                                {t('admin.settings.cash')}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </>
                            )}


                            {_tenant?.orderCatchword && (
                                <>
                                    <Grid item xs={4} >
                                        <Label text='order.catchword' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            variant='standard'
                                            value={orderCatchword}
                                            inputProps={{ maxLength: _tenant.orderCatchword }}
                                            onChange={handleCatchwordChange}
                                            sx={{ width: 300 }} />
                                    </Grid>
                                </>
                            )}

                            {_features?.ASE_1017?.status === "on" && (
                                <>
                                    <Grid item xs={4} >
                                        <Label text='order.alternativeAddress' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            onChange={() => handleExpandAltAddr()}>
                                        </Switch>
                                        <Collapse in={showAltContacts}
                                            orientation='vertical'>
                                            <AlternativeContacts />
                                        </Collapse>
                                    </Grid>
                                </>
                            )}

                            {_isCicAuthenticated && (
                                <>
                                    <Grid item xs={4} >
                                        <Label text='order.orderType' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            value={ordType}
                                            onChange={handleOrderTypeChange}
                                            variant="standard"
                                            sx={{ width: 300 }} >
                                            {_orderTypes.map((item, index) =>
                                                <MenuItem value={item.key}>{item.value}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                </>
                            )}

                            {_isCicAuthenticated && (
                                <>
                                    <Grid item xs={4} >
                                        <Label text='order.priceCalculation' />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Select
                                            value={priceCalc}
                                            onChange={handlePriceCalculationChanged}
                                            variant="standard"
                                            sx={{ width: 300 }} >
                                            <MenuItem value="regular">{t('order.priceRegular')}</MenuItem>
                                            <MenuItem value="fix">{t('order.priceFix')}</MenuItem>
                                            <MenuItem value="free">{t('order.priceNone')}</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {isFixPrice && (<TextField
                                            variant='standard'
                                            type="number"
                                            value={fixPrice}
                                            onChange={handleOrderFixPriceChange}
                                            sx={{ width: 150 }} />
                                        )}
                                    </Grid>
                                </>
                            )}

                            {_isCicAuthenticated && (
                                <>
                                    <Grid item xs={4} >
                                        <Label text='order.comment' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="stretch"
                                            spacing={2}
                                        >
                                            <TextareaAutosize
                                                value={ordComment}
                                                onChange={handleOrderCommentChange}
                                                variant="standard" />
                                        </Stack>
                                    </Grid>
                                </>
                            )}


                        </Grid>
                    </FormControl>
                </Box>
            </CardContent >
        </Card >
    );

}