import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Box,
    Grid,
    Skeleton,
    Stack,
    Tabs,
    Tab,
    tabsClasses,
    Typography,
    IconButton,
    Snackbar
} from '@mui/material';
import HtmlTooltip from '@mui/material/Tooltip';
import { CcMenuItem, CcSelect } from '../../AppTheme';

import { DataGrid } from '@mui/x-data-grid';

import OrderPicker from './OrderPicker';
import OrderActionsMenu from './OrderActionsMenu.jsx';
import { GridViewIcon, InfoIcon, MoreIcon, TableViewIcon } from '../../common/widgetTools';

import { OrderDetail } from './OrderCard';

import {
    fetchOrders,
    setOrdersPeriod,
    setOrdersLayout,
} from '../../redux/Actions.js';
import {
    orders,
    ordersPeriod,
    ordersLayout,
    datagridLocaleText,
    orderCancelled
} from '../../redux/Selectors.js';

import { cutCatchword } from '../../common/tools';

const TILE_LAYOUT = 'tile';
const GRID_LAYOUT = 'grid';
const stringList = array => {
    return array ? array.join(',') : '';
};

const renderPubDates = (params, t) => {

    const fpd = params.value[0].substring(0, 10);
    const lpd = params.value[params.value.length - 1].substring(0, 10);
    const pd = fpd !== lpd ? t('common.date.short', { value: new Date(fpd) }) + ' - ' + t('common.date.short', { value: new Date(lpd) }) : t('common.date.short', { value: new Date(fpd) });

    return (
        params.value.length === 1 ?
            (<span>{pd}</span>)
            :
            (<HtmlTooltip
                arrow
                placement="right-start"
                title={
                    <React.Fragment>
                        <Stack
                            direction='column'
                            spacing='10'
                            sx={{ width: 200, m: 10 }}>
                            <Typography
                                sx={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left', color: '#F4F4F4' }}>
                                {pd}
                            </Typography>
                            {params.value.map(it =>
                                <Typography
                                    sx={{ fontSize: 12, textAlign: 'left', color: '#F4F4F4' }}>
                                    {t('common.date.short', { value: new Date(it) })}
                                </Typography>
                            )}
                        </Stack>
                    </React.Fragment>
                }
            >
                <span>{pd}</span>
            </HtmlTooltip>)
    )
};

const renderEditions = (params) => {

    return (
        params.value.length === 1 ?
            (<span>{params.value[0]}</span>)
            :
            (<HtmlTooltip
                arrow
                placement="right-start"
                title={
                    <React.Fragment>
                        <Stack
                            direction='column'
                            spacing='10'
                            sx={{ width: 200, m: 10 }}>
                            {params.value.map(it =>
                                <Typography
                                    sx={{ fontSize: 12, textAlign: 'left', color: '#F4F4F4' }}>
                                    {it}
                                </Typography>
                            )}
                        </Stack>
                    </React.Fragment>
                }
            >
                <Stack direction="row"
                    justifyContent="left"
                    alignItems="center"
                    spacing={4}>
                    <span>{params.value[0]}</span>
                    <InfoIcon fontSize="small" />
                </Stack>
            </HtmlTooltip>)
    )
};

const orderList = (orders, t) => {
    if (!orders) {
        return [];
    }
    else {
        let mapped = orders.map(e => {
            const fpd = e.effectiveDates[0].substring(0, 10);
            const lpd = e.effectiveDates[e.effectiveDates.length - 1].substring(0, 10);
            return {
                id: e.orderCode,
                preview: e._links.preview.href,
                orderCode: e.orderCode,
                orderDate: e.created ? t('common.date.short', { value: new Date(e.created.substring(0, 10)) }) : null,
                editionPart: stringList(e.effectiveEditionPartNames),
                editions: e.effectiveEditionNames,
                pubDates: e.effectiveDates,
                firstpubDate: t('common.date.short', { value: new Date(fpd) }),
                lastPubDate: t('common.date.short', { value: new Date(lpd) }),
                catchWord: cutCatchword(e.orderCatchword, 20),
                //                price: t('global.currency.' + (e.orderPrice.currency || 'EUR'), { value: e.orderPrice.price }),
                price: Math.round((e.orderPrice?.price + Number.EPSILON) * 100) / 100,
                orderState: e.orderState
            }
        });
        return mapped;
    }
}


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Orders = ({ showBusyIndicator }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _orders = useSelector(state => orders(state));
    const _datagridLocaleText = useSelector(state => datagridLocaleText(state));
    const activeTab = useSelector(state => ordersLayout(state));
    const period = useSelector(state => ordersPeriod(state));
    const _orderCancelled = useSelector(state => orderCancelled(state));

    const [rows, setRows] = useState([]);
    const [pending, setPending] = useState(false);
    const [refreshCount, setRefreshCount] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');

    const refPicker = useRef(null);


    const calcHeight = () => {
        return window.innerHeight / 2 + 50;
    }
    const [height, setHeight] = useState(calcHeight());
    const listenResize = () => {
        setHeight(calcHeight());
    };

    useEffect(() => {
        listenResize();
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleMenuOpen = (e) => {
        setMenuAnchorEl(e.target);
        //        setMenuAnchorEl(document.getElementById('app-bar-id'));
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    }

    const rowSelected = orderCode => {
        let o = _orders.find(it => it.orderCode === orderCode);
        setSelectedOrder(o);
    };

    const columns = [
        {
            field: 'preview',
            headerName: '',
            renderCell: (params) => <img alt="" style={{ width: '50px' }} src={params.value} ></img >, width: 150
        },
        { field: 'orderCode', headerName: t('order.orderCode'), width: 150 },
        { field: 'catchWord', headerName: t('order.orderCatchword'), width: 200 },
        {
            field: 'pubDates',
            headerName: t('order.pubDates'),
            width: 230,
            renderCell: (params) => { return renderPubDates(params, t) }
        },
        // {field: 'firstPubDate', headerName: t('order.pubDates'), width: 150, type: Date },
        // {field: 'lastPubDate', headerName: t('order.lastPubDate'), width: 150, type: Date },
        {
            field: 'price',
            headerName: t('order.orderPrice'),
            width: 120,
            valueFormatter: params => {
                // todo: get currency by params.id from order-list
                return t('global.currency.' + ('EUR'), { value: params.value });
            },
            type: Number,
            align: 'right',
            headerAlign: 'right'
        },
        // {field: 'orderState', headerName: t('order.orderState'), width: 50 },
        { field: 'editionPart', headerName: t('order.editionPart'), width: 150 },
        {
            field: 'editions',
            headerName: t('order.editions'),
            width: 200,
            renderCell: params => { return renderEditions(params) }
        },
        {
            field: 'actions',
            headerName: '',
            renderCell: params => {
                return (<IconButton onClick={(e) => { handleMenuOpen(e) }}><MoreIcon /></IconButton>);
            },
            width: 50
        }
    ];

    useEffect(() => {
        showBusyIndicator(true);
        dispatch(fetchOrders(period, refreshCount));
        setPending(true);
        setRefreshCount(refreshCount + 1);
    }, [period]
    );

    useEffect(() => {
        dispatch(fetchOrders(period, refreshCount));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePeriodChanged = e => {
        dispatch(setOrdersPeriod(e.target.value));
    };

    useEffect(() => {
        showBusyIndicator(true);
        console.log("fetch orders ...");
        dispatch(fetchOrders(period, refreshCount));
        setPending(true);
        setRefreshCount(refreshCount + 1);
    }, [_orderCancelled]
    );

    useEffect(() => {
        console.log("called _orderCancelled ...")
        if (_orderCancelled) {
            if (_orderCancelled.success) {
                setSnackbarMsg(t('order.cancelled.success'));
                setSnackbarOpen(true);
                refPicker?.current?.closeDetailDialog();
                handleDialogClose('xx');
                showBusyIndicator(false);
            }
            else {
                setSnackbarMsg(t('order.cancelled.error'));
                setSnackbarOpen(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderCancelled]);

    useEffect(() => {
        setRows(orderList(_orders, t));
        setPending(false);
        showBusyIndicator(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orders]);

    useEffect(() => {
        if (selectedOrder) {
            //            setMenuAnchorEl(document.getElementById('app-bar-id'));
        }
    }, [selectedOrder]);

    const handleChange = (event, newValue) => {
        dispatch(setOrdersLayout(newValue));
    };

    const PeriodSelection = () => {
        const ID = 'select-period';
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing={'0.5em'}
            >
                <Typography variant="text" >{t('customerdata.order.period.title', { count: _orders.length })}</Typography>
                <CcSelect
                    id={ID}
                    value={period}
                    onChange={handlePeriodChanged}
                >
                    <CcMenuItem value={'month'}>{t('customerdata.order.period.month')}</CcMenuItem>
                    <CcMenuItem value={'half'}>{t('customerdata.order.period.half')}</CcMenuItem>
                    <CcMenuItem value={'year'}>{t('customerdata.order.period.year')}</CcMenuItem>
                </CcSelect>

            </Stack >
        )
    }

    const OrderTabs = () => {
        return (
            <Tabs sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                },
                mt: 0
            }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                value={activeTab}
                onChange={handleChange} >
                <Tab
                    value={TILE_LAYOUT}
                    iconPosition='start'
                    icon={<GridViewIcon size='lg' />}
                >
                </Tab>
                <Tab
                    value={GRID_LAYOUT}
                    iconPosition='start'
                    icon={<TableViewIcon size='lg' />}
                >
                </Tab>
            </Tabs >
        );
    }

    /**
     * Detail dialog like in tile view
     */
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = (ba) => {
        setDialogOpen(false);
    };

    const DetailDialog = () => {
        return selectedOrder && (
            <OrderDetail order={selectedOrder} handleDialogClose={handleDialogClose} dialogOpen={dialogOpen} isDraft={false} />
        );
    };

    return (
        <Stack
            sx={{ m: '1em' }}
            direction="column"
            justifyContent="space-between"
            spacing="0.5em"
            alignItems="left">

            <Typography variant="pageTitleBold">
                {t('customerdata.order.title')}
            </Typography>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <PeriodSelection />
                <OrderTabs />
            </Stack>

            <Box sx={{ height, width: '100%' }}>
                {GRID_LAYOUT === activeTab && (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        components={{
                            // Toolbar: GridToolbar,
                        }}
                        localeText={_datagridLocaleText}
                        onRowDoubleClick={(params, event, detail) => {
                            rowSelected(params.id);
                            handleDialogOpen();
                        }}
                        onRowClick={(params, event) => {
                            rowSelected(params.id);
                        }} />
                )}
                {TILE_LAYOUT === activeTab && (
                    <OrderPicker xHeight={height} orders={_orders} isDraft={false} ref={refPicker} />
                )}
            </Box>
            <OrderActionsMenu
                menuAnchorEl={menuAnchorEl}
                isMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                //                handleMenuClick={handleMenuClick}
                selectedOrder={selectedOrder}
            />
            <DetailDialog
                open={dialogOpen}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setSnackbarOpen(false);
                    setSnackbarMsg('');
                    // closeHandler();
                }}
                message={snackbarMsg}
            />       </Stack >
    );
}

/**
 *
 *
 * @param {*} orders
 * @returns
 */
export const OrdersSmall = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _orders = useSelector((state) => orders(state));
    const period = useSelector((state) => ordersPeriod(state));
    const [pending, setPending] = useState(true);
    //    const [timeoutId, setTimeoutId] = useState(0);
    const [list, setList] = useState([]);

    let timeoutId;
    useEffect(() => {
        timeoutId = setTimeout(() => { setPending(false) }, 5000);
        return (() => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        });
    }, []);

    useEffect(() => {
        setPending(true);
        dispatch(fetchOrders(period, 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    useEffect(() => {
        const l = []
        orderList(_orders, t).forEach((it, index, array) => {
            if (index < 3) {
                l.push(it);
            }
        });
        setPending(Boolean(_orders.length === 0));
        setList(l)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orders]);

    return (!pending ? (
        < Box >
            {
                list.map(it => {
                    return (<Grid container width='100%' key={it.orderCode}>
                        <Grid item xs={4} >
                            <Typography variant="cardData">
                                {t('common.date.long', { value: it.orderDate })}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}>
                                <Typography variant="cardData" sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                    {it.orderCode} - {it.catchWord}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    )
                })
            }
        </Box >
    ) : (< Box >
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
    ));
}


