import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { businessPartner, hideCustomerTypes } from '../redux/Selectors.js';
import { setUpsellingDone } from '../redux/Actions.js';
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import MarketPicker from '../components/MarketPicker.js';
import CustomerType from '../components/CustomerType.js';
import TemplatePicker from '../components/TemplatePicker';
import InfoText from '../components/InfoText';
import {
    Box,
    Divider,
    Paper,
    Stack
} from '@mui/material';

/**
 * @returns 
 */
export const Templates = () => {
    const dispatch = useDispatch();
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _hideCustomerTypes = useSelector((state) => hideCustomerTypes(state));
    const [height, setHeight] = useState();

    const listenResize = () => {
        setHeight(window.innerHeight - 180);
    };

    useEffect(() => {
        dispatch(setUpsellingDone(false));
        listenResize();
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack direction="column" spacing={5}>
            <AppHeader></AppHeader>

            <Box >
                <Paper sx={{ height, ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }} elevation={2} >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                        <MarketPicker />
                        <Box sx={{ pr: "1rem" }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                                <Divider orientation="vertical" flexItem ></Divider>
                                {!_businessPartner &&  !_hideCustomerTypes && (<CustomerType />)}
                            </Stack>
                        </Box>
                    </Stack>

                    <Divider />
                    <Box xsx={{ maxHeight: 100 }}>
                        <TemplatePicker xHeight={height}></TemplatePicker>
                    </Box>
                </Paper>
            </Box >

            <AppFooter logo={<img style={{ maxHeight: 50 }} alt="{_appTitle}" src="/webstore/resources/images/alfamedia-svg-70mm-RGB.svg"></img>} />
            <InfoText />
        </Stack >
    )
}

export default Templates;
